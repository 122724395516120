export const emailSettings = {
  serviceId: "service_cg3qkgk",
  appName: "Flowtient",
  templateId: "template_m7r0kqi",
  userId: "8H3omFeaMkljcda_F",
  accessToken: "77qPre5XNuVJnbzoa9OfV",
};

/*
  DOCUMENTATION

  The contact form uses EmailJS to send emails. All you need to do is signup to EmailJS at https://www.emailjs.com/
  and in your new account you will find the details to complete the above.

*/
